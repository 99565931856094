<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Reporte de Inventario</h5>
			</div>
			<div class="action-btn-wrap">
				
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<v-row>
				<v-col cols="12" xl="3" lg="3" md="3" sm="6">
					<el-input
						placeholder="Buscar..."
						v-model="search.value"
						style="width: 100%;"
						prefix-icon="el-icon-search"
						@input="getRecords"
					>
					</el-input>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="6" offset-xl="6" offset-lg="6" class="text-right">
					<v-btn v-if="records.length > 0" color="accent" @click="clickDownloadExcel()">
					<v-icon left small>mdi-file-excel</v-icon> Exportar Excel
				</v-btn>
				</v-col>
				<v-col cols="12" xl="12">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-right">N°</th>
									<th>PRODUCTO</th>
									<th class="text-right">STOCK</th>
									<th class="text-right">UNIDAD DE MEDIDA</th>
									<th class="text-right">ACCIONES</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in records" :key="index">
									<td class="text-right">{{ index + 1 }}</td>
									<td>{{ row.name }}</td>
									<td class="text-right">
										<strong>{{ row.stock }}</strong>
									</td>
									<td class="text-right">{{ row.unit_type }}</td>
									<td class="text-right">
										<v-btn
											class="my-1"
											color="info"
											fab
											x-small
											@click.prevent="viewKardex(row.id, row.type)"
										>
											<v-icon>mdi-loupe</v-icon>
										</v-btn>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
		</v-card>
		<kardex-dialog 
			:showDialog.sync="showKardexDialog" 
			:recordId="recordId"
			:type="type"
		>
		</kardex-dialog>
	</div>
</template>

<script>

import kardexDialog from "./Kardex.vue";

import queryString from 'query-string';
export default {
	components: {kardexDialog},
	data() {
		return {
			showDialog: false,
			resource: 'inventories',
			showKardexDialog: false,
			records: [],
			recordId: null,
			type: null,
			search: {
				value: null,
			},
		};
	},
	async created() {
		await this.getRecords();
	},
	methods: {
		getRecords() {
			return this.$http
				.get(`/${this.resource}/records?${this.getQueryParameters()}`)
				.then((response) => {
					this.records = response.data.data;
				});
		},
		getQueryParameters() {
			return queryString.stringify({
				...this.search,
			});
		},
		viewKardex(recordId, type) {
			this.recordId = recordId;
			this.type = type;
			this.showKardexDialog = true;
		},
		clickDownloadExcel() {

			// this.$http
			// 	.get(`/${this.resource}/excel`)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 	});

			this.$http({
				url: `/${this.resource}/excel`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				console.log(response.data)
			var fileURL = window.URL.createObjectURL(new Blob([response.data]));
			var fileLink = document.createElement('a');

			var date = new Date();

			let day = date.getDate();
			let month = date.getMonth() + 1;
			let year = date.getFullYear();

			fileLink.href = fileURL;
			fileLink.setAttribute('download', "ReporteDeInventario" + `-${day}-${month}-${year}` + ".xlsx");
			document.body.appendChild(fileLink);

			fileLink.click();
			});
		},
	},
};
</script>
