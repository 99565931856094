<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@open="create"
			@close="close"
			:close-on-click-modal="false"
			append-to-body
			top="7vh"
			width="70%"
            center
		>
			<div class="row">
				<div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
					<div class="form-group">
						<label class="control-label">Almacén</label>
						<el-select v-model="search.warehouse_id" @change="getRecords">
							<el-option
								v-for="option in warehouses"
								:key="option.id"
								:value="option.id"
								:label="option.name"
							></el-option>
						</el-select>
					</div>
				</div>
				<div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
					<div class="form-group">
						<label class="control-label">Fecha</label>
						<el-date-picker
							v-model="search.date"
							type="date"
							value-format="yyyy-MM-dd"
							@change="getRecords"
							format="dd/MM/yyyy"
							:picker-options="pickerOptions"
							:clearable="false"
						></el-date-picker>
					</div>
				</div>
			</div>
            <v-row>
                <v-col cols="12">
					<v-simple-table dense fixed-header>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-right">N°</th>
									<th>Fecha</th>
									<th>Responsable</th>
									<th>Tipo movimiento</th>
									<th class="text-right">Número</th>
									<th>Ingreso</th>
									<th>Salida</th>
									<th>Saldo</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in records" :key="index" :row="row">
									<td >{{ index + 1 }}</td>
									<td>{{ row.date_time }}</td>
									<td>{{ row.user }}</td>
									<td>{{ row.movement_type }}</td>
									<td >{{ row.number }}</td>
									<td >
										<span class="success--text">{{ row.input }}</span>
									</td>
									<td >
										<span class="danger--text">{{ row.output }}</span>
									</td>
									<td >
										<strong>{{ row.balance }}</strong>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>

                </v-col>
            </v-row>
		</el-dialog>
	</div>
</template>

<script>
import queryString from 'query-string';
import dayjs from 'dayjs';

export default {
	props: ['showDialog', 'recordId', 'type'],
	data() {
		return {
			showImportDialog: false,
			resource: 'kardex',
			titleDialog: 'Kardex',
			warehouses: [],
			search: {},
			records: [],
			pagination: {},
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
		};
	},
	methods: {
		async create() {
			await this.initSearch();
			await this.getTables();
			await this.getRecords();
		},
		initSearch() {
			this.records = [];
			this.search = {
				record_id: this.recordId,
				type: this.type,
				warehouse_id: null,
				date: dayjs().format('YYYY-MM-DD'),
			};
		},
		async getTables() {
			await this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.warehouses = response.data.warehouses;

				this.search.warehouse_id =
					this.warehouses.length > 0 ? this.warehouses[0].id : null;
			});
		},
		getRecords() {
			return this.$http
				.get(`/${this.resource}/search?${this.getQueryParameters()}`)
				.then((response) => {
					this.records = response.data.data;
				});
		},
		getQueryParameters() {
			return queryString.stringify({
				...this.search,
			});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initSearch();
		},
	},
};
</script>
